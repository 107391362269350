import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { SelectOption } from '@/interfaces/common.interface'

@Component({
  name: 'GtrRegistrationModuleEditPromoCodeView',
  computed: {
    ...mapState('promocodes', ['promocode']),
    ...mapState('option', ['option_groups'])
  }
})
export default class GtrRegistrationModuleEditPromoCodeView extends Vue {
  option_groups!: any[]

  data () {
    return {
      loading: false,
      submitting: false,
      promoCode: {
        code: null,
        discount_type: null,
        group_discount_type: 'ALL_GROUP_MEMBERS',
        discount_amount: null,
        discount_percent: null,
        discount_fixed_price: null,
        cap: null,
        active_start_date: null,
        active_end_date: null,
        notes: null,
        reg_types: [],
        promo_code_options: []
      },
      true_false_options: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ],
      group_style_options: [
        {
          text: 'All Group Members',
          value: 'ALL_GROUP_MEMBERS'
        },
        {
          text: 'Single Group Member',
          value: 'SINGLE_GROUP_MEMBER'
        }
      ],
      discount_type_items: [
        {
          text: 'Amount',
          value: 'AMOUNT'
        },
        {
          text: 'Percent',
          value: 'PERCENT'
        // },
        // {
        //   text: 'Fixed Price',
        //   value: 'FIXED'
        }
      ]
    }
  }

  @Watch('promocode')
  onPromoCodeChange (payload: any) {
    if (payload) {
      const promoCode = payload
      if (promoCode) {
        this.$data.promoCode = promoCode
        if (promoCode.active_start_date) {
          this.$data.promoCode.active_start_date = new Date(promoCode.active_start_date)
        }
        if (promoCode.active_end_date) {
          this.$data.promoCode.active_end_date = new Date(promoCode.active_end_date)
        }
        if (promoCode?.reg_types?.length) {
          this.$data.promoCode.reg_types = promoCode.reg_types.filter((regTypeUuid: string) => this.registration_types.some((regType: SelectOption) => regType.value === regTypeUuid))
        }
        if (promoCode?.promo_code_options?.length) {
          this.$data.promoCode.promo_code_options = promoCode.promo_code_options
            .map(option => option.option.uuid)
            .filter(
              (optionUuid: string) => Object.values(this.options_with_prices)
                .some((options: SelectOption[]) => options.some(option => option.value === optionUuid))
            )
        }
      }
    }
  }

  async mounted () {
    await this.fetchOptionGroups()
    await this.fetchPromoCode()
  }

  async submit () {
    const form = (this.$refs.editPromoCodeForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          promocode_uuid: this.$route.params.promo_code_uuid,
          data: {
            code: this.$data.promoCode.code,
            discount_percent: this.$data.promoCode.discount_percent,
            discount_amount: this.$data.promoCode.discount_amount,
            discount_type: this.$data.promoCode.discount_type,
            group_discount_type: this.$data.promoCode.group_discount_type,
            discount_fixed_price: this.$data.promoCode.discount_fixed_price,
            cap: this.$data.promoCode.cap,
            active_start_date: this.$data.promoCode.active_start_date,
            active_end_date: this.$data.promoCode.active_end_date,
            notes: this.$data.promoCode.notes,
            regtype_specific: this.$data.promoCode.regtype_specific,
            reg_types: this.$data.promoCode.reg_types,
            option_specific: this.$data.promoCode.option_specific,
            promo_code_options: this.$data.promoCode.promo_code_options
          }
        }
        await this.$store.dispatch('promocodes/editPromoCode', payload)
        Container.get(Notification).success('Promo code successfully edited.')
        this.$router.push({
          name: 'level-two.modules.registration.promo-codes'
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }

  private handleOptionSpecificChange (value: boolean) {
    if (!value) {
      this.$data.promoCode.promo_code_options = []
    }
  }

  private handleRegtypeSpecificChange (value: boolean) {
    if (!value) {
      this.$data.promoCode.reg_types = []
    }
  }

  private async fetchPromoCode () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('promocodes/getPromoCode', { event_uuid: this.$route.params.event_uuid, promo_code_uuid: this.$route.params.promo_code_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchOptionGroups () {
    try {
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private optionGroupItemTitle (titleText: string, groupOptions: SelectOption[]): string {
    const selectedOptions = this.$data.promoCode?.promo_code_options?.filter(selectedOptionUuid => groupOptions.some(groupOption => groupOption.value === selectedOptionUuid)).length ?? 0
    const optionsInGroup = groupOptions.length
    return `<span style="color: var(${selectedOptions === optionsInGroup ? '--complete-dark' : '--grey-75'});">${titleText} (${selectedOptions}/${optionsInGroup})</span>`
  }

  get options_with_prices (): { [index: string]: SelectOption[] } {
    if (!this.option_groups?.length) {
      return {}
    }

    const pricedOptions: { [index: string]: SelectOption[] } = {}
    for (let i = 0; i < this.option_groups.length; i++) {
      const optionGroup = this.option_groups[i]
      if (optionGroup.type === 'LROPTIONGRP') {
        continue
      }
      for (let j = 0; j < optionGroup.options.length; j++) {
        const option = optionGroup.options[j]
        if (option.pricing?.length) {
          if (!pricedOptions[optionGroup.name]) {
            pricedOptions[optionGroup.name] = [] as SelectOption[]
          }
          pricedOptions[optionGroup.name].push({
            text: option.name,
            value: option.uuid
          })
        }
      }
    }
    return pricedOptions
  }

  get registration_types (): SelectOption[] {
    if (!this.option_groups?.length) {
      return []
    }
    const registration_types_array = this.option_groups
      .filter(group => group.name === 'Registration Types')
      .shift().options
    const registration_types: SelectOption[] = []
    for (let i = 0; i < registration_types_array.length; i++) {
      registration_types.push({
        text: registration_types_array[i].name,
        value: registration_types_array[i].uuid
      })
    }
    return registration_types
  }

  changeDiscountType () {
    if (this.$data.promoCode.discount_type && this.$data.promoCode.discount_type === 'AMOUNT') {
      this.$data.promoCode.discount_percent = null
    }
    if (this.$data.promoCode.discount_type && this.$data.promoCode.discount_type === 'PERCENT') {
      this.$data.promoCode.discount_amount = null
    }
  }
}
